var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.ready)?_c('v-row',{attrs:{"justify":"space-between","no-gutters":""}},[_c('v-card',{attrs:{"flat":"","width":"300px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"block":"","max-width":"100%","outlined":"","to":"/dashboard"}},[_vm._v(" Назад ")])],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-card',{attrs:{"outlined":"","width":"350"}},[_c('v-navigation-drawer',{attrs:{"permanent":"","width":"350"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" Мастер создания проекта ")])],1)],1),_c('v-row',{staticClass:"ma-2",attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('v-card',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-card-title',{staticClass:"pa-2 title"},[_vm._v(" "+_vm._s(_vm.name ? _vm.name : "Название проекта")+" ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){_vm.showName = !_vm.showName}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-"+_vm._s(_vm.showName ? "down" : "right")+" ")])],1)],1)],1)],1)],1),_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showName),expression:"showName"}],staticClass:"mx-2",attrs:{"color":!_vm.name ? 'red lighten-5' : '',"outlined":""}},[_c('v-card-text',[_c('v-text-field',{attrs:{"clearable":"","counter":"64","dense":"","label":"Имя проекта"},on:{"input":function ($event) {
                                                if ($event && _vm.step === -1)
                                                    { _vm.step = 0; }
                                            }},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-textarea',{attrs:{"auto-grow":"","clearable":"","counter":"65535","dense":"","hint":"Не обязательно","label":"Описание проекта","persistent-hint":"","row-height":"1"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1),_c('v-row',{staticClass:"ma-2",attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('v-card',[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-card-title',{staticClass:"pa-2 title"},[_vm._v(" Этапы ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){_vm.showSteps = !_vm.showSteps}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-"+_vm._s(_vm.showSteps ? "down" : "right")+" ")])],1)],1)],1)],1)],1),_c('v-expand-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSteps),expression:"showSteps"}],staticClass:"mx-2 mb-2",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":"","nav":""}},_vm._l((_vm.steps),function(s,i){return _c('div',{key:i},[(i !== 0)?_c('v-divider'):_vm._e(),_c('v-list-item',{attrs:{"disabled":i === 1 &&
                                                        !_vm.nozologies.length,"ripple":""},on:{"click":function($event){_vm.step = i}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(s.name)+" "),(
                                                                _vm.stepsCompleted.includes(
                                                                    s
                                                                )
                                                            )?_c('v-icon',{attrs:{"color":"green","dense":"","size":"medium"}},[_vm._v("mdi-check ")]):_vm._e()],1),_c('v-list-item-subtitle',{staticClass:"text-right"},[_vm._v(" Шаг "+_vm._s(i + 1)+" ")])],1)],1)],1)}),0)],1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"mx-4"},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center justify-center",staticStyle:{"word-break":"normal"},domProps:{"innerHTML":_vm._s(_vm.tip)}})],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","no-gutters":""}},[(_vm.step === 0)?_c('v-nozologies-picker',{attrs:{"nozologyList":_vm.nozologyList},model:{value:(_vm.nozologies),callback:function ($$v) {_vm.nozologies=$$v},expression:"nozologies"}}):_vm._e(),(_vm.step === 1)?_c('v-amounts-picker',{model:{value:(_vm.nozologies),callback:function ($$v) {_vm.nozologies=$$v},expression:"nozologies"}}):_vm._e(),(_vm.step === 2)?_c('v-projtype-picker',{attrs:{"typeList":_vm.typeList},model:{value:(_vm.project_type),callback:function ($$v) {_vm.project_type=$$v},expression:"project_type"}}):_vm._e(),(_vm.step === 3)?_c('v-projinfo',{attrs:{"description":_vm.description,"name":_vm.name,"nozoList":_vm.nozologies,"projectType":_vm.project_type,"typeList":_vm.typeList}}):_vm._e()],1)],1),_c('v-card',{staticClass:"hidden-md-and-down",attrs:{"flat":""}},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.stepName)}})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }