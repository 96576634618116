<template>
    <v-col cols="12" class="py-0">
        <v-row no-gutters justify="center" align="center">
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Выбранная нозология</th>
                            <th class="text-left">
                                Количество первично выявленных пациентов
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(nozo, i) in value" :key="nozo.id">
                            <td class="title">{{ nozo.full_name }}</td>
                            <td>
                                <v-text-field
                                    :min="0"
                                    :step="100"
                                    @input="updateAmount(i, Number($event))"
                                    outlined
                                    class="my-1"
                                    hide-details
                                    dense
                                    type="number"
                                    :value="nozo.amount"
                                ></v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-row>
        <v-row justify="center">
            <v-col class="text-center">
                <v-btn
                    color="primary"
                    :disabled="!passed"
                    outlined
                    @click="$parent.nextStep()"
                >
                    Подтвердить и перейти к следующему шагу
                </v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({}),
    mounted() {
        this.value.forEach((nozo, i) => {
            if (!nozo.amount) this.value[i].amount = 0;
            this.$set(this.value, i, nozo);
        });
    },
    methods: {
        updateAmount(i, val) {
            const nozo = this.value[i];
            nozo.amount = val;
            this.$set(this.value, i, nozo);
        }
    },
    computed: {
        passed() {
            return this.value.every(
                nozo => nozo.amount > 0 && Number.isSafeInteger(nozo.amount)
            );
        }
    }
};
</script>
