var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","disable-pagination":"","disable-filtering":"","disable-sort":"","headers":[
                {
                    text: 'Нозология',
                    value: 'full_name'
                }
            ],"items":_vm.nozologyList,"item-key":"id","show-select":""},on:{"input":_vm.updateNozos},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
            var props = ref.props;
            var on = ref.on;
return [_c('v-simple-checkbox',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticStyle:{},attrs:{"color":"primary"}},'v-simple-checkbox',props,false),on))]}},{key:"item.data-table-select",fn:function(ref){
            var select = ref.select;
            var isSelected = ref.isSelected;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.full_name",fn:function(ref){
            var value = ref.value;
return [_c('p',{staticClass:"ma-0 title"},[_vm._v(_vm._s(value))])]}},{key:"header.full_name",fn:function(ref){
            var header = ref.header;
return [_c('p',{staticClass:"ma-0 title"},[_vm._v(_vm._s(header.text))])]}}]),model:{value:(_vm.selectedNozos),callback:function ($$v) {_vm.selectedNozos=$$v},expression:"selectedNozos"}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.passed,"outlined":""},on:{"click":function($event){return _vm.$parent.nextStep()}}},[_vm._v(" Подтвердить и перейти к следующему шагу ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }