<template>
    <v-col cols="12">
        <v-col>
            <v-row class="mx-2" justify="center">
                <v-col cols="12" class="text-center"
                    >Тип расчёта проекта:</v-col
                >
                <v-card-title
                    class="mx-2 subtitle-2 pt-0 justify-center text-center text-wrap"
                    justify="center"
                    v-html="typeList.find(t => t.id === projectType).name"
                />
            </v-row>
        </v-col>
        <v-row justify="center" no-gutters>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Выбранная нозология</th>
                            <th class="text-right">
                                Количество первично выявленных пациентов
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="nozo in nozoList" :key="nozo.id">
                            <td>{{ nozo.full_name }}</td>
                            <td align="right">
                                <span
                                    :class="{
                                        'red--text': nozo.amount === 0
                                    }"
                                    >{{ nozo.amount }}</span
                                >
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-row>
        <v-row justify="center">
            <v-col class="text-center">
                <v-btn color="primary" outlined @click="createProject">
                    Создать проект
                </v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>
<script>
export default {
    props: ["name", "description", "projectType", "typeList", "nozoList"],
    computed: {
        nozologies() {
            const r = {};
            this.nozoList.forEach(nozo => {
                r[nozo.id] = nozo.amount;
            });
            return r;
        },
        project_type() {
            return this.projectType;
        }
    },
    methods: {
        createProject() {
            this.$store.dispatch("loading", true);
            this.axios
                .post("/api/projects", {
                    name: this.name,
                    description: this.description,
                    nozologies: this.nozologies,
                    project_type_id: this.project_type
                })
                .then(resp => {
                    this.$router.push(`/dashboard/${resp.data.id}`);
                });
        }
    }
};
</script>
