<template>
    <v-col cols="12">
        <v-row no-gutters justify="center" align="center">
            <v-card>
                <v-list>
                    <v-list-item-group
                        @change="$emit('input', $event)"
                        color="primary"
                        :value="value"
                    >
                        <v-list-item
                            :value="type.id"
                            v-for="type in typeList"
                            :key="type.id"
                        >
                            <template v-slot:default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="text-wrap"
                                        v-html="type.name"
                                    ></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-checkbox
                                        :input-value="active"
                                        color="primary"
                                        @click="1"
                                    ></v-checkbox>
                                </v-list-item-action>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-row>
        <v-row justify="center">
            <v-col class="text-center">
                <v-btn
                    color="primary"
                    :disabled="!passed"
                    outlined
                    @click="$parent.nextStep()"
                >
                    Подтвердить и перейти к следующему шагу
                </v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    props: ["value", "typeList"],
    data: () => ({
        types: []
    }),
    mounted() {
        this.selectedType = this.$parent.project_type;
    },
    computed: {
        passed() {
            return this.value !== undefined;
        }
    }
};
</script>
