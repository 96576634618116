<template>
    <v-container fluid>
        <v-row v-if="ready" justify="space-between" no-gutters>
            <v-card flat width="300px">
                <v-row no-gutters>
                    <v-btn block max-width="100%" outlined to="/dashboard">
                        Назад
                    </v-btn>
                </v-row>
                <v-row class="mt-2" no-gutters>
                    <v-card outlined width="350">
                        <v-navigation-drawer permanent width="350">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="title">
                                        Мастер создания проекта
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-row align="center" class="ma-2" no-gutters>
                                <v-col>
                                    <v-card>
                                        <v-row
                                            align="center"
                                            justify="space-between"
                                            no-gutters
                                        >
                                            <v-card-title class="pa-2 title">
                                                {{
                                                    name
                                                        ? name
                                                        : "Название проекта"
                                                }}
                                            </v-card-title>
                                            <v-btn
                                                class="mr-2"
                                                icon
                                                @click="showName = !showName"
                                            >
                                                <v-icon large>
                                                    mdi-chevron-{{
                                                        showName
                                                            ? "down"
                                                            : "right"
                                                    }}
                                                </v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-expand-transition>
                                <v-card
                                    v-show="showName"
                                    :color="!name ? 'red lighten-5' : ''"
                                    class="mx-2"
                                    outlined
                                >
                                    <v-card-text>
                                        <v-text-field
                                            v-model="name"
                                            clearable
                                            counter="64"
                                            dense
                                            label="Имя проекта"
                                            @input="
                                                $event => {
                                                    if ($event && step === -1)
                                                        step = 0;
                                                }
                                            "
                                        ></v-text-field>
                                        <v-textarea
                                            v-model="description"
                                            auto-grow
                                            clearable
                                            counter="65535"
                                            dense
                                            hint="Не обязательно"
                                            label="Описание проекта"
                                            persistent-hint
                                            row-height="1"
                                        ></v-textarea>
                                    </v-card-text>
                                </v-card>
                            </v-expand-transition>
                            <v-row align="center" class="ma-2" no-gutters>
                                <v-col>
                                    <v-card>
                                        <v-row
                                            align="center"
                                            justify="space-between"
                                            no-gutters
                                        >
                                            <v-card-title class="pa-2 title">
                                                Этапы
                                            </v-card-title>
                                            <v-btn
                                                class="mr-2"
                                                icon
                                                @click="showSteps = !showSteps"
                                            >
                                                <v-icon large>
                                                    mdi-chevron-{{
                                                        showSteps
                                                            ? "down"
                                                            : "right"
                                                    }}
                                                </v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-expand-transition>
                                <v-card
                                    v-show="showSteps"
                                    class="mx-2 mb-2"
                                    outlined
                                >
                                    <v-card-text class="pa-0">
                                        <v-list class="pa-0" dense nav>
                                            <div
                                                v-for="(s, i) in steps"
                                                :key="i"
                                            >
                                                <v-divider v-if="i !== 0" />
                                                <v-list-item
                                                    :disabled="
                                                        i === 1 &&
                                                            !nozologies.length
                                                    "
                                                    ripple
                                                    @click="step = i"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ s.name }}
                                                            <v-icon
                                                                v-if="
                                                                    stepsCompleted.includes(
                                                                        s
                                                                    )
                                                                "
                                                                color="green"
                                                                dense
                                                                size="medium"
                                                                >mdi-check
                                                            </v-icon>
                                                        </v-list-item-title>

                                                        <v-list-item-subtitle
                                                            class="text-right"
                                                        >
                                                            Шаг {{ i + 1 }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-expand-transition>
                        </v-navigation-drawer>
                    </v-card>
                </v-row>
            </v-card>
            <v-col class="mx-4">
                <v-row justify="center" no-gutters>
                    <v-card>
                        <v-card-title
                            class="text-center justify-center"
                            style="word-break: normal;"
                            v-html="tip"
                        />
                    </v-card>
                </v-row>
                <v-row class="mt-2" justify="center" no-gutters>
                    <v-nozologies-picker
                        v-if="step === 0"
                        v-model="nozologies"
                        :nozologyList="nozologyList"
                    />
                    <v-amounts-picker v-if="step === 1" v-model="nozologies" />
                    <v-projtype-picker
                        v-if="step === 2"
                        v-model="project_type"
                        :typeList="typeList"
                    />
                    <v-projinfo
                        v-if="step === 3"
                        :description="description"
                        :name="name"
                        :nozoList="nozologies"
                        :projectType="project_type"
                        :typeList="typeList"
                    />
                </v-row>
            </v-col>

            <v-card class="hidden-md-and-down" flat>
                <v-row justify="end" no-gutters>
                    <v-card>
                        <v-card-title
                            class="text-center"
                            v-html="stepName"
                        ></v-card-title>
                    </v-card>
                </v-row>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
import VNozologiesPicker from "../../components/ProjectAdd/Nozologies.vue";
import VAmountsPicker from "../../components/ProjectAdd/Amounts.vue";
import VProjtypePicker from "../../components/ProjectAdd/ProjectType.vue";
import VProjinfo from "../../components/ProjectAdd/ProjectInfo.vue";

export default {
    components: {
        VNozologiesPicker,
        VAmountsPicker,
        VProjtypePicker,
        VProjinfo
    },
    data() {
        return {
            ready: false,

            step: -1,
            steps: [
                { name: "Выбор нозологий" },
                { name: "Количество пациентов" },
                { name: "Тип проекта" }
            ],
            showSteps: true,

            name: "",
            description: "",
            showName: true,

            nozologyList: [],
            nozologies: [],

            typeList: [],
            project_type: undefined
        };
    },
    mounted() {
        this.getProjectConstants();
    },
    methods: {
        getProjectConstants() {
            const nozos = this.axios.get("/api/nozologies");
            const types = this.axios.get("/api/types");
            this.axios.all([nozos, types]).then(
                this.axios.spread((...responses) => {
                    this.nozologyList = responses[0].data;
                    this.typeList = responses[1].data;
                    this.$store.dispatch("loading", false);
                    this.ready = true;
                })
            );
        },
        nextStep() {
            if (!this.name) {
                this.step = -1;
            } else if (this.stepsUncompleted.length) {
                this.step = this.steps.indexOf(this.stepsUncompleted[0]);
            } else {
                this.step = 3;
            }
        },
        createProject() {
            if (!this.canCreate) return;
            this.$store.dispatch("loading", true);

            this.axios
                .post("/api/projects", {
                    name: this.project_name,
                    description: this.project_description
                })
                .then(response => {
                    if (response.data.success) this.$router.push("/dashboard");
                });
        }
    },
    computed: {
        stepsCompleted() {
            const r = [];
            if (this.nozologies.length > 0) {
                r.push(this.steps[0]);
            }
            if (r.length) {
                if (this.nozologies.every(nozo => nozo.amount > 0)) {
                    r.push(this.steps[1]);
                }
            }
            if (this.project_type !== undefined) {
                r.push(this.steps[2]);
            }
            return r;
        },
        stepsUncompleted() {
            return this.steps.filter(
                step => this.stepsCompleted.indexOf(step) < 0
            );
        },
        stepName() {
            if (this.step === -1) {
                return 'Предварительный шаг: <br />"Название проекта"';
            }
            if (this.step === this.steps.length) {
                return "Проект подготовлен!";
            }
            return `Шаг ${this.step + 1}:<br />"${this.steps[this.step].name}"`;
        },
        canCreate() {
            if (this.stepsCompleted.length === this.steps.length && this.name) {
                return true;
            }
            return false;
        },
        tip() {
            let tip = "";
            if (this.step === -1) {
                tip =
                    "Для начала дайте проекту название и, по желанию, добавьте описание.";
            } else if (this.step === 0) {
                tip =
                    "Теперь выберите нозологии, с которыми Вы будете работать в этом проекте.";
            } else if (this.step === 1) {
                tip =
                    "Введите количество первично выявленных пациентов, для которых Вы будете производить расчёты по каждой нозологии в рамках календарного года.";
            } else if (this.step === 2) {
                tip = "Выберите тип расчёта проекта.";
            } else {
                tip = `Предварительные настройки проекта завершены. <br /> Далее Вам будет необходимо  <br /> ${
                    this.project_type === 1
                        ? "выбрать форму представления результатов расчёта."
                        : "произвести ручную настройку проекта."
                }`;
            }
            return tip;
        }
    }
};
</script>
