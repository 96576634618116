<template>
    <v-col cols="12" class="py-0">
        <v-row no-gutters justify="center" align="center">
            <v-data-table
                v-model="selectedNozos"
                @input="updateNozos"
                hide-default-footer
                disable-pagination
                disable-filtering
                disable-sort
                :headers="[
                    {
                        text: 'Нозология',
                        value: 'full_name'
                    }
                ]"
                :items="nozologyList"
                item-key="id"
                show-select
                class="elevation-1"
            >
                <template v-slot:header.data-table-select="{ props, on }">
                    <v-simple-checkbox
                        style="primary--text"
                        color="primary"
                        v-ripple
                        v-bind="props"
                        v-on="on"
                    ></v-simple-checkbox>
                </template>
                <template
                    v-slot:item.data-table-select="{ select, isSelected }"
                >
                    <v-simple-checkbox
                        color="primary"
                        v-ripple
                        :value="isSelected"
                        @input="select($event)"
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.full_name="{ value }">
                    <p class="ma-0 title">{{ value }}</p>
                </template>
                <template v-slot:header.full_name="{ header }">
                    <p class="ma-0 title">{{ header.text }}</p>
                </template>
            </v-data-table>
        </v-row>
        <v-row justify="center">
            <v-col class="text-center">
                <v-btn
                    color="primary"
                    :disabled="!passed"
                    outlined
                    @click="$parent.nextStep()"
                >
                    Подтвердить и перейти к следующему шагу
                </v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    props: ["value", "nozologyList"],
    data: () => ({
        selectedNozos: []
    }),
    methods: {
        updateNozos() {
            this.$emit("input", this.selectedNozos);
        }
    },
    mounted() {
        this.selectedNozos = this.value;
    },
    computed: {
        passed() {
            return Object.keys(this.value).length;
        }
    }
};
</script>
